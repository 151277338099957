import { Component } from 'react'
import { DecodedItem, ParcelStatus, ShippingParcel } from 'stylewhere/api'
import { Box, ParcelRowItemList, ParcelRowStatus, RowProps, SmallTagCounter, StatusChip } from 'stylewhere/components'
import styled from '@emotion/styled'
import { __ } from 'stylewhere/i18n'
import { ShippingExtensions } from 'stylewhere/extensions'
import { OperationConfig, OperationReadingProvider } from 'stylewhere/shared'

interface Props extends RowProps {
  parcel: ShippingParcel
  expanded: boolean
  expandable: boolean
  loading: boolean
  details: DecodedItem[]
  onClick: () => void
  operation: OperationConfig
}

function getStatusColor(status: ParcelStatus): 'warning' | 'error' | 'default' | 'success' {
  switch (status) {
    case 'INBOUND_ERROR':
    case 'OUTBOUND_ERROR':
      return 'error'

    case 'READY_TO_RECEIVE':
    case 'RECEIVE_AWAIT':
    case 'SEND_AWAIT':
    case 'DRAFT':
      return 'warning'

    case 'RECEIVED':
      return 'success'

    case 'IN_INBOUND':
    case 'READY_TO_SEND':
    case 'IN_TRANSIT':
    default:
      return 'default'
  }
}

export class ParcelRow extends Component<Props> {
  getLabel = (product) => {
    let label = ''
    if (product) {
      if (
        product.style &&
        ((product.style.description && product.style.description != null) ||
          (product.style.value && product.style.value != null))
      )
        label +=
          product.style.description && product.style.description != null
            ? product.style.description
            : product.style.value
      if (product.material)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.material.description && product.material.description != null
            ? product.material.description
            : product.material.value)
      if (product.color)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.color.description && product.color.description != null
            ? product.color.description
            : product.color.value)
      if (product.size)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.size.description && product.size.description != null ? product.size.description : product.size.value)
    }
    return label !== '' ? label : '---'
  }

  render() {
    const { parcel, onClick, loading, expanded, expandable, operation, details, ...rest } = this.props

    const groupByProduct = operation.readingsDisplayMode === 'groupedByProduct'
    const rows = OperationReadingProvider.groupItems(
      details && Array.isArray(details) ? details : [],
      groupByProduct,
      operation,
      ShippingExtensions,
      parcel.checklist,
      parcel.products,
      true
    )

    const checklist = parcel.checklist
    const detectedItems = parcel.parcelEntryQuantity !== null ? parcel.parcelEntryQuantity ?? 0 : 0
    const unexpectedItems = parcel.parcelUnexpectedQuantity !== null ? parcel.parcelUnexpectedQuantity ?? 0 : 0
    const expectedItems: number | undefined = checklist
      ? (checklist.checklistItems?.length ?? 0) +
        (checklist.checklistProducts?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) ?? 0)
      : undefined

    return (
      <Box flex mb={15}>
        <SessionRow onClick={onClick} row vcenter>
          <Box row vcenter flex>
            <Box width={'20px'} vcenter>
              <ParcelRowStatus loading={loading} expandable={expandable} expanded={expanded} />
            </Box>
            <Session row flex vcenter>
              <Box width={350}>{parcel.code}</Box>
              <Box width={560}>
                <StatusChip status={parcel.status} customStatusType={getStatusColor(parcel.status)} />
              </Box>
              <Box width={240} ml={'10px'}>
                <SmallTagCounter
                  alignSelf={'start'}
                  detected={detectedItems}
                  expected={expectedItems}
                  unexpected={unexpectedItems}
                />
              </Box>
            </Session>
          </Box>
        </SessionRow>
        {expanded && expandable && (
          <ParcelRowItemList items={rows} parcel={parcel} operation={operation} viewHeader={true} />
        )}
      </Box>
    )
  }
}

const SmallLabel = styled((props) => <Box {...props} />)`
  font-weight: 400;
  font-size: 14px;
`

const SessionRow = styled(Box)`
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 15px 20px;
  margin-bottom: 10px;
`

const Session = styled(Box)`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #454545;
  margin-left: 7px;
  justify-content: space-between;
`
