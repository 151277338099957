import { Component } from 'react'
import { Box, Button, Icons, Input, List, OperationList, Page, ShippingRow } from 'stylewhere/components'
import { Router, ShippingOperationConfig } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { Asn, AsnStatus, Shippings } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { capitalize, showToastError } from 'stylewhere/shared/utils'


export interface OnSelectAsnCodeParams {
  selectedAsn?: Asn;
  currentAsnCode?: string;
}

export interface Props {
  operation: ShippingOperationConfig
  onSelectAsnCode: (params: OnSelectAsnCodeParams) => void
  onClickReadParcel: () => void
}

interface State {
  shipments?: Asn[];
  search?: string;
  loading: boolean
}

export const getShippingOperationalStatuses = (operationConfig: ShippingOperationConfig): AsnStatus[] => {
  if (operationConfig.type === 'INBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_INBOUND', 'INBOUND_ERROR']
  if (operationConfig.type === 'OUTBOUND') return ['DRAFT', 'IN_TRANSIT', 'IN_OUTBOUND', 'INBOUND_ERROR']
  return []
}

export class ShippingByAsnStartListAndInput extends Component<Props> {
  state: State = {
    shipments: undefined,
    search: undefined,
    loading: true
  }

  isModal = false

  operation: ShippingOperationConfig

  isWithInput: boolean

  constructor(props: Props) {
    super(props)
    this.operation = props.operation
    this.isWithInput = props.operation?.startAsnScreen === "asnListAndInputCode"
  }

  onRowClick = (asn: Asn) => this.props.onSelectAsnCode({selectedAsn: asn})

  updateShipments = async (search?: string) => {
    const { operation } = this.props
    this.setState({ loading: true })
    
    let shipments: Asn[] = []
    try {
      shipments = (
        await Shippings.getAsnList(
          operation,
          100, // FIXME - impostato fisso per il momento
          0, // FIXME
          search,
          getShippingOperationalStatuses(operation),
        )
      ).content
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }

    this.setState({ shipments, search, loading: false })
  }

  async componentDidMount() {
    await this.updateShipments()
  }

  shipmentRender = (shipping: Asn) => {
    return <ShippingRow shipping={shipping} onClick={() => this.onRowClick(shipping)} />
  }

  render() {
    const { shipments, loading } = this.state
    const { operation } = this.props
    const operationType = operation.type.toLowerCase()
    return (
      <Page title={`${operation.description ?? operationType}`} onBackPress={() => Router.navigate('/')}>
        <Page.Content notBoxed>
          <Box p={5} pb={0} mb={40} row justify={'space-between'}>
            <Input
              onChange={this.updateShipments}
              placeholder={`${__(T.misc.shipment)}`}
              image={<Icons.Barcode />}
              style={{ width: 325 }}
              autoFocus
              debounce={300}
            />
            {!this.isWithInput && <Box />}
            {this.isWithInput && <Button
              title={`${__(T.misc.new_shipment)}`}
              disabled={!this.state.search}
              onClick={() => {
                if (this.state.search) {
                  this.props.onSelectAsnCode({currentAsnCode: this.state.search})
                }
              }}
            />}
          </Box>
          <List
            title={`${__(T.titles.shipments)} (${shipments?.length ?? 0})`}
            loading={loading}
            data={shipments}
            rowRender={this.shipmentRender}
          />
          {this.operation.startWithRfidSearch &&
            <Box p={5} pb={0} mt={40} row justify={'flex-end'}>
              <Button
                title={__(T.misc.read_parcel)}
                onClick={this.props.onClickReadParcel}
              />
            </Box>}
        </Page.Content>
      </Page>
    )
  }
}
