import { Routes as CoreRoutes } from 'core/pages'
import EncodingVerify from './Encoding/EncodingVerify'

///////////////////////////////////////////////////////////////////////////////
// Custom & core overrides, change only this!
///////////////////////////////////////////////////////////////////////////////

/*
const CustomRoutes = {
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-parcel-list/:opCode': { component: SimpleParcelListStart },
}
*/

const CustomDirectRoutes = {}
const CustomRoutes = { '/encoding/:opCode/verify': { component: EncodingVerify } }

///////////////////////////////////////////////////////////////////////////////

export const Routes = { ...CustomDirectRoutes, ...CoreRoutes, ...CustomRoutes }
// eslint-disable-next-line
export type Routes = keyof typeof Routes
